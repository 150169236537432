import React, { Component } from "react";
import "react-bootstrap";

import {
  Form,
  Modal,
  Button,
  Accordion,
  AccordionToggle,
  AccordionCollapse,
  Image,
} from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import "./AddRole.css";
import "../../shared/styles/floating-label.css";
import HTTP from "../../shared/utility/http";
import { RiErrorWarningFill } from "react-icons/ri";
import ExpandIcon from "../../images/ic-expand.svg";
import CollapseIcon from "../../images/ic-collapse.svg";

class AddRole extends Component {
  constructor() {
    super();

    this.state = {
      showModal: false,
      roleName: "",
      moduleList: [],
      productTypeList: [],
      grantedGeneralPermissions: [],
      grantedProductPermissions: {},
      isSelectAll: false,
      isRoleNameEmpty: false,
      isPermissionListEmpty: false,
      walletPermission: {
        walletAdjustment: {
          id: 506,
          isDisplay: false,
          minAmount: "",
          maxAmount: "",
          isMinAmountValid: true,
          isMaxAmountValid: true,
        },
        walletReplenishment: {
          id: 507,
          isDisplay: false,
          minAmount: "",
          maxAmount: "",
          isMinAmountValid: true,
          isMaxAmountValid: true,
        },
      },
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onSelectAllCheckboxChange = this.onSelectAllCheckboxChange.bind(this);
    this.onSelectAllModuleCheckboxChange =
      this.onSelectAllModuleCheckboxChange.bind(this);
    this.storeWalletAmountValidation =
      this.storeWalletAmountValidation.bind(this);
    this.storeWalletOnCheckboxChange =
      this.storeWalletOnCheckboxChange.bind(this);
    this.checkApprovalAmountCheckbox =
      this.checkApprovalAmountCheckbox.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.isSelectAllCategory = this.isSelectAllCategory.bind(this);
    this.selectAllCategory = this.selectAllCategory.bind(this);
  }

  componentDidMount() {
    this.setState({ showModal: this.props.display });
  }

  onInputChange(e) {
    const name = e.target.name;
    const val = e.target.value;
    if (name == "roleName") {
      const isRoleNameEmpty = val.trim() !== "" ? false : true;
      this.setState({ [name]: val, isRoleNameEmpty: isRoleNameEmpty });
    }

    let wallet = this.state.walletPermission;
    if (name.toString().includes("Approve Wallet Adjustment")) {
      const amount = val.toString().replace(/,/g, "");
      const formattedAmount = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.storeWalletAmountValidation(
        wallet,
        "walletAdjustment",
        name,
        formattedAmount
      );
    } else if (name.toString().includes("Approve Wallet Replenishment")) {
      const amount = val.toString().replace(/,/g, "");
      const formattedAmount = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.storeWalletAmountValidation(
        wallet,
        "walletReplenishment",
        name,
        formattedAmount
      );
    }
  }

  onBlur(e) {
    const name = e.target.name;
    const val = e.target.value;
    if (name == "roleName") {
      const isRoleNameEmpty = val !== "" ? false : true;
      this.setState({ isRoleNameEmpty: isRoleNameEmpty });
    } else {
      if (val !== "") {
        let wallet = this.state.walletPermission;
        const amount = parseFloat(val.toString().replace(/,/g, "")).toFixed(2);
        const formattedAmount = amount
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (name.toString().includes("Approve Wallet Adjustment")) {
          this.storeWalletAmountValidation(
            wallet,
            "walletAdjustment",
            name,
            formattedAmount
          );
        } else if (name.toString().includes("Approve Wallet Replenishment")) {
          this.storeWalletAmountValidation(
            wallet,
            "walletReplenishment",
            name,
            formattedAmount
          );
        }
      }
    }
  }

  isDisabled() {
    if (this.state.walletPermission.walletAdjustment.isDisplay) {
      if (this.checkIfApprovalAmountIsValid("walletAdjustment")) {
        return true;
      }
    }
    if (this.state.walletPermission.walletReplenishment.isDisplay) {
      if (this.checkIfApprovalAmountIsValid("walletReplenishment")) {
        return true;
      }
    }
    return false;
  }

  toggleModal() {
    this.fetchPermissions();
    if (this.state.showModal) {
      this.resetStateComponent();
    }
    this.setState({ showModal: !this.state.showModal });
  }

  resetForm() {
    this.setState({
      grantedGeneralPermissions: [],
      grantedProductPermissions: {},
      isSelectAll: false,
      walletPermission: {
        walletAdjustment: {
          id: 506,
          isDisplay: false,
          minAmount: "",
          maxAmount: "",
          isMinAmountValid: true,
          isMaxAmountValid: true,
        },
        walletReplenishment: {
          id: 507,
          isDisplay: false,
          minAmount: "",
          maxAmount: "",
          isMinAmountValid: true,
          isMaxAmountValid: true,
        },
      },
      isSelectAll: false,
    });
  }

  storeWalletApprovalInfo(walletState, permission, field, value) {
    let walletPermission = walletState;
    let adjustment = walletPermission[permission];
    adjustment[field] = value;
    walletPermission[permission] = adjustment;
    walletState = walletPermission;

    return walletState;
  }

  storeWalletAmountValidation(wallet, walletPermission, name, val) {
    const permission = walletPermission;
    const field = name.toString().split("-")[1];
    const oppositeField = field == "minAmount" ? "maxAmount" : "minAmount";

    const validNumberRegEx = /^(?=.)(\d{1,3}(,\d{3})*)?(\.\d+)?$/;
    const isValidNumber = validNumberRegEx.test(val);
    const fieldValidation =
      field == "minAmount" ? "isMinAmountValid" : "isMaxAmountValid";
    wallet = this.storeWalletApprovalInfo(wallet, permission, field, val);
    if (isValidNumber) {
      wallet = this.storeWalletApprovalInfo(
        wallet,
        permission,
        fieldValidation,
        true
      );

      const permissionApprovalAmount = this.state.walletPermission[permission];
      const currentFieldAmount = parseFloat(
        permissionApprovalAmount[field].toString().replace(/,/g, "")
      ).toFixed(2);
      const oppositeFieldAmount = parseFloat(
        permissionApprovalAmount[oppositeField].toString().replace(/,/g, "")
      ).toFixed(2);
      if (
        Number(currentFieldAmount) < 1 ||
        Number(currentFieldAmount) > 1000000000
      ) {
        wallet = this.storeWalletApprovalInfo(
          wallet,
          permission,
          fieldValidation,
          false
        );
      } else {
        if (
          field == "minAmount" &&
          permissionApprovalAmount[oppositeField] != ""
        ) {
          if (Number(currentFieldAmount) < Number(oppositeFieldAmount)) {
            wallet = this.storeWalletApprovalInfo(
              wallet,
              permission,
              "isMinAmountValid",
              true
            );
            wallet = this.storeWalletApprovalInfo(
              wallet,
              permission,
              "isMaxAmountValid",
              true
            );
          } else {
            wallet = this.storeWalletApprovalInfo(
              wallet,
              permission,
              "isMinAmountValid",
              false
            );
          }
        }
        if (
          field == "maxAmount" &&
          permissionApprovalAmount[oppositeField] != ""
        ) {
          if (Number(currentFieldAmount) > Number(oppositeFieldAmount)) {
            wallet = this.storeWalletApprovalInfo(
              wallet,
              permission,
              "isMaxAmountValid",
              true
            );
            wallet = this.storeWalletApprovalInfo(
              wallet,
              permission,
              "isMinAmountValid",
              true
            );
          } else {
            wallet = this.storeWalletApprovalInfo(
              wallet,
              permission,
              "isMaxAmountValid",
              false
            );
          }
        }
      }
    } else {
      wallet = this.storeWalletApprovalInfo(
        wallet,
        permission,
        fieldValidation,
        false
      );
    }
    this.setState({ walletPermission: wallet });
  }

  storeWalletOnCheckboxChange(wallet, permission, id, isChecked) {
    wallet = this.storeWalletApprovalInfo(
      wallet,
      permission,
      "isDisplay",
      isChecked
    );
    wallet = this.storeWalletApprovalInfo(wallet, permission, "id", id);
    if (!isChecked) {
      wallet = this.storeWalletApprovalInfo(
        wallet,
        permission,
        "minAmount",
        ""
      );
      wallet = this.storeWalletApprovalInfo(
        wallet,
        permission,
        "maxAmount",
        ""
      );
      wallet = this.storeWalletApprovalInfo(
        wallet,
        permission,
        "isMinAmountValid",
        true
      );
      wallet = this.storeWalletApprovalInfo(
        wallet,
        permission,
        "isMaxAmountValid",
        true
      );
    }
    return wallet;
  }

  checkApprovalAmountCheckbox(checkboxLabel = "", wallet, id, isChecked) {
    const permissionId = isChecked ? id : 0;
    if (checkboxLabel == "Approve Wallet Adjustment") {
      wallet = this.storeWalletOnCheckboxChange(
        wallet,
        "walletAdjustment",
        permissionId,
        isChecked
      );
    } else if (checkboxLabel == "Approve Wallet Replenishment") {
      wallet = this.storeWalletOnCheckboxChange(
        wallet,
        "walletReplenishment",
        permissionId,
        isChecked
      );
    }
    return wallet;
  }

  checkIfApprovalAmountIsValid(permission) {
    const isValid =
      !this.state.walletPermission[permission].isMinAmountValid ||
      !this.state.walletPermission[permission].isMaxAmountValid ||
      this.state.walletPermission[permission].minAmount == "" ||
      this.state.walletPermission[permission].maxAmount == "";
    return isValid;
  }

  getPermissionsCount() {
    const generalModule = this.state.moduleList.filter(
      (module) => module.name != "Product Management"
    );
    const productModule = this.state.moduleList.find(
      (module) => module.name == "Product Management"
    );
    const productTypeList = this.state.productTypeList;
    let currentGeneralPermissions = [];
    let currentProductPermissions = [];
    this.state.grantedGeneralPermissions?.forEach((id) => {
      generalModule.forEach((module) => {
        if (module.permissions.find((permission) => permission.id == id)) {
          currentGeneralPermissions.push(id);
        }
      });
    });
    this.state.productTypeList.forEach((productType) => {
      this.state.grantedProductPermissions[productType.code].forEach((id) => {
        if (
          productModule.permissions.find((permission) => permission.id == id)
        ) {
          currentProductPermissions.push(id);
        }
      });
    });
    return currentGeneralPermissions.length + currentProductPermissions.length;
  }

  onSelectAllCheckboxChange(isChecked) {
    let generalPermissionIds = [];
    let productPermissionIds = {};
    let walletPermission = this.state.walletPermission;
    this.state.productTypeList.forEach((productType) => {
      productPermissionIds[productType.code] = [];
    });
    const generalModules = this.state.moduleList.filter(
      (module) => module.name != "Product Management"
    );
    const productModules = this.state.moduleList.find(
      (module) => module.name == "Product Management"
    );
    if (isChecked == true) {
      if (generalModules) {
        generalModules.forEach((module) => {
          module.permissions.forEach((permission) => {
            generalPermissionIds.push(permission.id);
          });
        });
      }
      if (productModules) {
        this.state.productTypeList.forEach((productType) => {
          productModules.permissions.forEach((permission) => {
            productPermissionIds[productType.code].push(permission.id);
          });
        });
      }
    }
    generalModules.forEach((module) => {
      module.permissions.forEach((permission) => {
        walletPermission = this.checkApprovalAmountCheckbox(
          permission.name,
          walletPermission,
          permission.id,
          isChecked
        );
      });
    });
    this.setState({
      grantedGeneralPermissions: generalPermissionIds,
      grantedProductPermissions: productPermissionIds,
      isSelectAll: isChecked,
      walletPermission: walletPermission,
    });
  }

  isSelectAll() {
    const generalModule = this.state.moduleList.filter(
      (module) => module.name != "Product Management"
    );
    const productModule = this.state.moduleList.find(
      (module) => module.name == "Product Management"
    );
    const productTypeList = this.state.productTypeList;
    let currentGeneralPermissions = [];
    let currentProductPermissions = [];
    let maxGeneralPermissions = 0;
    let maxProductPermissions = 0;
    let isSelectAll = false;
    if (generalModule && productModule && productTypeList) {
      generalModule.forEach((module) => {
        maxGeneralPermissions += module.permissions.length;
      });
      productTypeList.forEach((productType) => {
        maxProductPermissions += productModule.permissions.length;
      });
      this.state.grantedGeneralPermissions?.forEach((id) => {
        generalModule.forEach((module) => {
          if (module.permissions.find((permission) => permission.id == id)) {
            if (!currentGeneralPermissions.includes(id))
              currentGeneralPermissions.push(id);
          }
        });
      });
      productTypeList.forEach((productType) => {
        this.state.grantedProductPermissions[productType.code].forEach((id) => {
          if (
            productModule.permissions.find((permission) => permission.id == id)
          ) {
            currentProductPermissions.push(id);
          }
        });
      });
    }
    isSelectAll =
      currentGeneralPermissions.length >= maxGeneralPermissions &&
      currentProductPermissions.length >= maxProductPermissions;
    this.setState({ isSelectAll: isSelectAll });
    return isSelectAll;
  }

  onSelectAllModuleCheckboxChange(e, name, key) {
    const module = this.state.moduleList.find((module) => module.name == name);
    const modulePermissionIds = module.permissions.map(
      (permission) => permission.id
    );
    let currentModulePermissions = [];
    if (!key) {
      currentModulePermissions = this.state.grantedGeneralPermissions;
      currentModulePermissions = currentModulePermissions.filter(
        (id) => !modulePermissionIds.includes(id)
      );
      if (e.target.checked == true) {
        module.permissions.forEach((permission) => {
          currentModulePermissions.push(permission.id);
        });
      }
      this.setState({
        grantedGeneralPermissions: currentModulePermissions,
      });
    } else if (this.state.productTypeList.some((type) => type.code === key)) {
      if (e.target.checked == true) {
        module.permissions.forEach((permission) => {
          currentModulePermissions.push(permission.id);
        });
      }
      this.setState({
        grantedProductPermissions: {
          ...this.state.grantedProductPermissions,
          [key]: currentModulePermissions,
        },
      });
    } else {
      /* NON PRODUCT PERMISSIONS */
      currentModulePermissions = this.state.grantedGeneralPermissions;
      const subModulePermissionIds = module.permissions.map((permission) => {
        if (permission.submodule === key || permission.submodule === null) {
          return permission.id;
        }
      });
      currentModulePermissions = currentModulePermissions.filter(
        (id) => !subModulePermissionIds.includes(id)
      );

      if (e.target.checked === true) {
        module.permissions.forEach((permission) => {
          if (permission.submodule === key || permission.submodule === null) {
            // this should select channel management wide only but inserted the permissions to other submodule - done
            currentModulePermissions.push(permission.id); //this selects all 39 - done
          }
        });
      }

      this.setState({
        grantedGeneralPermissions: currentModulePermissions,
      });
    }

    let walletPermission = this.state.walletPermission;
    module.permissions.forEach((permission) => {
      if (permission.submodule === key) {
        walletPermission = this.checkApprovalAmountCheckbox(
          permission.name,
          walletPermission,
          permission.id,
          e.target.checked
        );
      }
    });
    this.setState({
      walletPermission,
    });
    e.stopPropagation();
  }

  selectAllCategory(e, dataCategory) {
    const categoryIds = dataCategory.map(({ id }) => id);
    let currentModulePermissions = [];

    currentModulePermissions = this.state.grantedGeneralPermissions;
    currentModulePermissions = currentModulePermissions.filter(
      (id) => !categoryIds.includes(id)
    );
    if (e.target.checked === true) {
      dataCategory.forEach(({ id }) => {
        currentModulePermissions.push(id);
      });
    }

    this.setState({
      grantedGeneralPermissions: currentModulePermissions,
    });
  }

  isSelectAllCategory(dataCategory) {
    const categoryIds = dataCategory.map(({ id }) => id);
    const findIds = this.state.grantedGeneralPermissions.filter(
      (grantedGeneralPermissionsIds) => {
        return categoryIds.some(
          (catIds) => catIds === grantedGeneralPermissionsIds
        );
      }
    );

    return categoryIds.length === findIds.length;
  }

  isSelectAllModule(name, key) {
    const module = this.state.moduleList.find((module) => module.name == name);
    let currentModulePermissions = [];
    if (!key) {
      this.state.grantedGeneralPermissions.forEach((id) => {
        if (module.permissions.find((permission) => permission.id == id)) {
          if (!currentModulePermissions.includes(id))
            currentModulePermissions.push(id);
        }
      });
    } else if (this.state.productTypeList.some((type) => type.code === key)) {
      this.state.grantedProductPermissions[key]?.forEach((id) => {
        if (module.permissions.find((permission) => permission.id == id)) {
          if (!currentModulePermissions.includes(id))
            currentModulePermissions.push(id);
        }
      });
    } else {
      this.state.grantedGeneralPermissions.forEach((id) => {
        module.permissions.find((permission) => {
          if (permission.id === id) {
            if (permission.submodule === null || permission.submodule === key) {
              // added submodule === null to handle non product module without submodule
              if (!currentModulePermissions.includes(id)) {
                currentModulePermissions.push(id);
              }
            }
          }
        });
      });

      return (
        currentModulePermissions.length ===
        module.permissions.filter(
          (permission) =>
            permission.submodule === key || permission.submodule === null
        ).length
      );
    }
    return currentModulePermissions.length == module.permissions.length;
  }

  onCheckboxChange(event, permissionId, key) {
    const module = this.state.moduleList.find((module) =>
      module.permissions.some((permission) => permission.id == permissionId)
    );
    const modulePermissionIds = module.permissions.map(
      (permission) => permission.id
    );
    let walletPermission = this.state.walletPermission;
    let currentModulePermissions = [];
    let isModuleIncluded = false;
    if (!key) {
      currentModulePermissions = this.state.grantedGeneralPermissions;

      if (event.target.checked == true) {
        currentModulePermissions.push(permissionId);
      } else {
        currentModulePermissions = currentModulePermissions.filter(
          (id) => id != permissionId
        );
      }

      // Check if any permission of the module is selected
      isModuleIncluded = module.permissions.some((permission) =>
        currentModulePermissions.includes(permission.id)
      );

      this.setState({
        grantedGeneralPermissions: currentModulePermissions,
      });
    } else if (this.state.productTypeList.some((type) => type.code === key)) {
      currentModulePermissions =
        this.state.grantedProductPermissions[key] || [];

      if (event.target.checked == true) {
        currentModulePermissions.push(permissionId);
      } else {
        currentModulePermissions = currentModulePermissions.filter(
          (id) => id != permissionId
        );
      }

      // Check if any permission of the module is selected
      isModuleIncluded = module.permissions.some((permission) =>
        currentModulePermissions.includes(permission.id)
      );

      this.setState({
        grantedProductPermissions: {
          ...this.state.grantedProductPermissions,
          [key]: currentModulePermissions,
        },
      });
    } else {
      currentModulePermissions = this.state.grantedGeneralPermissions;

      if (event.target.checked === true) {
        currentModulePermissions.push(permissionId);
      } else {
        currentModulePermissions = currentModulePermissions.filter(
          (id) => id != permissionId
        );
      }

      // Check if any permission of the module is selected
      //isModuleIncluded = module.permissions.some(permission => currentModulePermissions.includes(permission.id));

      this.setState({
        grantedGeneralPermissions: currentModulePermissions,
      });
    }

    if (isModuleIncluded) {
      // Include the module
    } else {
      // Exclude the module
    }

    module.permissions.forEach((permission) => {
      if (permission.name === key) {
        walletPermission = this.checkApprovalAmountCheckbox(
          permission.name,
          walletPermission,
          permission.id,
          event.target.checked
        );
      }
    });
    this.setState({
      walletPermission: walletPermission,
    });
    event.stopPropagation();
  }

  channelModulesMap = {
        "Channel": [
            "Add New Channel",
            "View Channel List",
            "View Primary Information",
            "View Contract Details",
            "View Reports",
            "Download Contract Details Attachment",
            "Edit Channel Status",
            "Edit Primary Information",
            "Edit Contract Details",
            "Edit Reports",
            "Edit Draft",
            "Save Channel as Draft",
            "View Draft List",
            "View Channel Approval List",
            "View Service Fee Share Approval List",
            "Approve Channel Request",
            "Approve Service Fee Share Request",
            "Reject Channel Request",
            "Reject Service Fee Share Request",
            "Resubmission of Rejected Channel Request",
            "View Products Tab",
            "View Enable/Disable Product Tab",
            "Enable Product",
            "Disable Product",
            "Enable Partner",
            "Disable Partner",
            "Enable Branch",
            "Disable Branch",
            "Enable TPAID",
            "Disable TPAID",
            "View Service Fee Share Tab",
            "Edit Service Fee Share",
            "View Channel Audit Trail",
            "View Channel Integration",
            "View Channel Client Credentials",
            "Regenerate Client Credentials",
            "Send Client Credentials",
            "View Channel Public Certificate",
            "Download Public Certificate"
        ],
        "Branch": [
            "Add New Branch",
            "Edit Branch",
            "Edit Branch Status",
            "Save Branch as Draft",
            "View Branch",
            "View Branch List",
            "View Branch Audit Trail"
        ],
        "TPAID": [
            "Add New TPAID",
            "Edit TPAID",
            "Edit TPAID Status",
            "Save TPAID as Draft",
            "View TPAID",
            "View TPAID List",
            "View TPAID Audit Trail"
        ]
    };

  groupPermissionBySubModule(module) {
    /**
     * Group array by submodule (if submodule == null module name will be use)
     * Time Complexity: O(n)
     */
    const objval = Object.values(
      module.permissions.reduce((acc, permission) => {
        const submodule = permission.submodule || permission.module;
        if (!acc[submodule]) {
          acc[submodule] = { name: submodule, permissions: [] };
        }
        acc[submodule].permissions.push(permission);
        return acc;
      }, {})
    );
    return Object.values(objval)
  }
  
  mapChannelModules(module) {
        const channelSubModules = Object.keys(this.channelModulesMap);
        const objval = channelSubModules.reduce((acc, submodule) => {
            const perms = this.channelModulesMap[submodule].map((permission) => {
                return module.permissions.find((modulePermission) => {
                    return modulePermission.name.toUpperCase() === permission.toUpperCase()
                })
                
            })
            const permissions = perms.filter(Boolean);
            if (!acc[submodule]) {
                acc[submodule] = { name: submodule, permissions };
            }
            acc[submodule].permissions = permissions;
            return acc;
        }, {})
        return Object.values(objval);
    }

  resetStateComponent() {
    this.setState({
      roleName: "",
      isSelectAll: false,
      checkedItems: new Map(),
      isRoleNameEmpty: false,
      isPermissionListEmpty: false,
      walletPermission: {
        walletAdjustment: {
          id: 0,
          isDisplay: false,
          minAmount: "",
          maxAmount: "",
          isMinAmountValid: true,
          isMaxAmountValid: true,
        },
        walletReplenishment: {
          id: 0,
          isDisplay: false,
          minAmount: "",
          maxAmount: "",
          isMinAmountValid: true,
          isMaxAmountValid: true,
        },
      },
    });
    this.resetForm();
  }

  async onFormSubmit(event) {
    event.preventDefault();
    let message = "",
      type = "";
    const walletPermission = this.state.walletPermission;
    let body = {
      name: this.state.roleName,
      description: this.state.roleName,
      permissions: this.state.grantedGeneralPermissions,
      product_permissions: this.state.grantedProductPermissions,
    };
    try {
      await HTTP.post("/v3/roles/", body)
        .then(({ data: { id: roleId } }) => {
          for (const permission in walletPermission) {
            const {
              id: permissionId,
              minAmount,
              maxAmount,
            } = walletPermission[permission];
            const data = {
              roleId,
              permissionId,
              minAmount,
              maxAmount,
            };

            if (permissionId && minAmount != "" && maxAmount != "") {
              HTTP.post("/v2/permissions/approval", data);
            }
          }
          message = `${body.name} role has been added successfully`;
          type = "success";
        })
        .catch((error) => {
          if (error.response.status === 422) {
            message = `${body.name} role is already taken.`;
            type = "failed";
          } else {
            message = `${body.name} role is not successfully added`;
            type = "failed";
          }
        })
        .finally(() => {
          this.props.parentCallback(message, type);
          this.resetForm();
          this.toggleModal();
        });
    } catch (e) {
      message = `${body.name} role is not successfully added`;
      type = "failed";
      this.props.parentCallback(message, type);
      this.toggleModal();
    }
  }

  async fetchProductTypes() {
    let productTypeList = [];
    let productPermissions = {};
    await HTTP.get("/v3/products/types")
      .then((resp) => {
        productTypeList = resp.data.data || [];
        productTypeList.forEach((productType) => {
          productPermissions[productType.code] = [];
        });
        this.setState({
          productTypeList: productTypeList,
          grantedProductPermissions: productPermissions,
        });

        //handling when user clicks select all before /types loads
        if (this.state.isSelectAll) {
          this.onSelectAllCheckboxChange(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return productTypeList;
  }

  async fetchPermissions() {
    let moduleList = [];
    let activeKeys = {};
    await HTTP.get(`/v3/permissions`)
      .then((response) => {
        let permissions = response.data.data;
        let moduleNameList = [];
        permissions.forEach((x) => {
          if (!moduleNameList.includes(x.module) && x.module) {
            moduleNameList.push(x.module);
            moduleList.push({
              name: x.module,
              permissions: [],
            });
            activeKeys[x.module.replace(" ", "")] = true;
          }
        });
        moduleList.forEach((module) => {
          module.permissions.push(
            ...permissions.filter((y) => y.module === module.name)
          );
        });
        this.setState({
          moduleList: moduleList,
          activeKeys: activeKeys,
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        this.fetchProductTypes();
      });
  }

  render() {
    const walletPermission = this.state.walletPermission;
    const transactionManagementPermissions = this.state.moduleList
      ? this.state.moduleList.filter(
          (module) => module.name == "Transaction Management"
        )
      : [];
    const centralWalletSystemPermissions = this.state.moduleList
      ? this.state.moduleList.filter(
          (module) => module.name === "Central Wallet System"
        )
      : [];
    const productManagementPermissions = this.state.moduleList
      ? this.state.moduleList.filter(
          (module) => module.name == "Product Management"
        )
      : [];
    const productManagementModules = [
      "Wallet Adjustment",
      "Wallet Management",
      "Wallet Replenishment",
      "Wallet List",
      "Cash Flow",
      "Product Management",
      "Transaction Management",
    ];

    const reportsPermissions = this.state.moduleList
      ? this.state.moduleList.filter(({ name }) => name === "Reports")
      : [];

    const reportMaintenancePermissions = this.state.moduleList
      ? this.state.moduleList.filter(
          ({ name }) => name === "Report Maintenance"
        )
      : [];
    const reportsGenPermission = [
      {
        name: "Business Reports and Generation",
        submodule: "Reports",
        permissions:
          reportsPermissions && reportsPermissions.length > 0
            ? reportsPermissions[0].permissions
            : [],
        category: {
          "Product Reports":
            reportsPermissions && reportsPermissions.length > 0
              ? reportsPermissions[0].permissions.filter(
                  (data) => data.submodule === "Product Reports"
                )
              : [],
          "Channel Reports":
            reportsPermissions && reportsPermissions.length > 0
              ? reportsPermissions[0].permissions.filter(
                  (data) => data.submodule === "Channel Reports"
                )
              : [],
          "Reconciliation Reports":
            reportsPermissions && reportsPermissions.length > 0
              ? reportsPermissions[0].permissions.filter(
                  (data) => data.submodule === "Reconciliation Reports"
                )
              : [],
          "Finance Reports":
            reportsPermissions && reportsPermissions.length > 0
              ? reportsPermissions[0].permissions.filter(
                  (data) => data.submodule === "Finance Reports"
                )
              : [],
          "Accounting Reports":
            reportsPermissions && reportsPermissions.length > 0
              ? reportsPermissions[0].permissions.filter(
                  (data) => data.submodule === "Accounting Reports"
                )
              : [],
          "RPA Reports":
            reportsPermissions && reportsPermissions.length > 0
              ? reportsPermissions[0].permissions.filter(
                  (data) => data.submodule === "RPA Reports"
                )
              : [],
          "Other Reports":
            reportsPermissions && reportsPermissions.length > 0
              ? reportsPermissions[0].permissions.filter(
                  (data) => data.submodule === "Other Reports"
                )
              : [],
        },
      },
      {
        name: "Business Reports and Generation",
        submodule: "Report Maintenance",
        permissions:
          reportMaintenancePermissions &&
          reportMaintenancePermissions.length > 0
            ? reportMaintenancePermissions[0].permissions
            : [],
        category: {
          "Product Reports":
            reportMaintenancePermissions &&
            reportMaintenancePermissions.length > 0
              ? reportMaintenancePermissions[0].permissions.filter(
                  (data) => data.submodule === "Product Reports"
                )
              : [],
          "Channel Reports":
            reportMaintenancePermissions &&
            reportMaintenancePermissions.length > 0
              ? reportMaintenancePermissions[0].permissions.filter(
                  (data) => data.submodule === "Channel Reports"
                )
              : [],
          "Reconciliation Reports":
            reportMaintenancePermissions &&
            reportMaintenancePermissions.length > 0
              ? reportMaintenancePermissions[0].permissions.filter(
                  (data) => data.submodule === "Reconciliation Reports"
                )
              : [],
          "Finance Reports":
            reportMaintenancePermissions &&
            reportMaintenancePermissions.length > 0
              ? reportMaintenancePermissions[0].permissions.filter(
                  (data) => data.submodule === "Finance Reports"
                )
              : [],
          "Accounting Reports":
            reportMaintenancePermissions &&
            reportMaintenancePermissions.length > 0
              ? reportMaintenancePermissions[0].permissions.filter(
                  (data) => data.submodule === "Accounting Reports"
                )
              : [],
          "RPA Reports":
            reportMaintenancePermissions &&
            reportMaintenancePermissions.length > 0
              ? reportMaintenancePermissions[0].permissions.filter(
                  (data) => data.submodule === "RPA Reports"
                )
              : [],
          "Other Reports":
            reportMaintenancePermissions &&
            reportMaintenancePermissions.length > 0
              ? reportMaintenancePermissions[0].permissions.filter(
                  (data) => data.submodule === "Other Reports"
                )
              : [],
        },
      },
    ];
    const centralWalletSystemGroupedPermissions =
      centralWalletSystemPermissions.length > 0
        ? this.groupPermissionBySubModule(centralWalletSystemPermissions[0])
        : [];
    const generalPermissions = this.state.moduleList
      ? this.state.moduleList.filter(
          (module) =>
            !productManagementModules.includes(module.name) &&
            module.name !== "Central Wallet System" &&
            module.name !== "Old Business Reports and Generation" &&
            module.name !== "Business Reports and Generation" &&
            module.name !== "Reports" &&
            module.name !== "Report Maintenance"
        )
      : [];
    return (
      <>
        <Modal
          size="xl"
          centered
          show={this.state.showModal}
          contentClassName="add-role-modal"
          onHide={this.toggleModal}
        >
          <Modal.Body>
            <h3 className="modal-title">Add Role</h3>
            <Form onSubmit={this.onFormSubmit}>
              <Form.Group controlId="formAddRoleName">
                <Row className="p-1">
                  <Col>
                    <div className="input-block">
                      <Form.Control
                        name="roleName"
                        type="text"
                        required
                        maxLength={255}
                        onChange={this.onInputChange}
                        onBlur={this.onBlur}
                        className={this.state.isRoleNameEmpty ? "isError" : ""}
                      ></Form.Control>
                      <span className="placeholder">Role Name</span>
                      {this.state.isRoleNameEmpty && (
                        <small style={{ color: "#FF0000" }}>
                          <RiErrorWarningFill /> Role Name is required.
                        </small>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                controlId="formAllPermission"
                className="permission-list"
              >
                <Row className="m-3">
                  <Col>
                    <Form.Check
                      type="checkbox"
                      id="all-permissions"
                      label="Select All Permissions"
                      onChange={(v) =>
                        this.onSelectAllCheckboxChange(v.target.checked)
                      }
                    />
                  </Col>
                </Row>
                <hr className="mx-4" />
                {/* FOR Business Reports and Generation Permission */}
                {reportsPermissions.length > 0 &&
                  reportMaintenancePermissions.length > 0 && (
                    <>
                      <div className="px-4 pt-4">
                        <label className="px-4">
                          <strong>Business Reports and Generation</strong>
                        </label>
                      </div>
                      <div className="px-4">
                        {reportsGenPermission.map((module, i) => {
                          const submoduleId = module.submodule.replace(" ", "");
                          const submodule = module.submodule;
                          const category = module.category;
                          const categoryKeys = Object.keys(category);
                          return (
                            <Accordion key={i} defaultActiveKey={submoduleId}>
                              <Col
                                lg="12"
                                className="px-5 checkbox-holder pt-4"
                                key={submoduleId}
                              >
                                <Form.Check
                                  checked={this.isSelectAllModule(submodule)}
                                  type="checkbox"
                                  id={submoduleId}
                                  label={
                                    <>
                                      {submodule}
                                      <AccordionToggle
                                        style={{
                                          border: "none",
                                          background: "none",
                                        }}
                                        eventKey={submoduleId}
                                        onClick={(evt) => {
                                          this.setState({
                                            activeKeys: {
                                              ...this.state.activeKeys,
                                              [submoduleId]:
                                                !this.state.activeKeys[
                                                  submoduleId
                                                ],
                                            },
                                          });
                                        }}
                                      >
                                        <label className="expandCollapseLink">
                                          {this.state.activeKeys[
                                            submoduleId
                                          ] === true ? (
                                            <Image
                                              className="expandIcon"
                                              src={ExpandIcon}
                                            />
                                          ) : (
                                            <Image
                                              className="collapseIcon"
                                              src={CollapseIcon}
                                            />
                                          )}
                                        </label>
                                      </AccordionToggle>
                                    </>
                                  }
                                  onChange={(e) =>
                                    this.onSelectAllModuleCheckboxChange(
                                      e,
                                      submodule
                                    )
                                  }
                                />
                              </Col>
                              <AccordionCollapse eventKey={submoduleId}>
                                <Row lg="12">
                                  <Col
                                    lg={{ span: 11, offset: 1 }}
                                    className="checkbox-holder px-1 py-2"
                                    key={submodule}
                                  >
                                    {categoryKeys.map((key) => {
                                      const categoryPermission = category[key];
                                      const idCategory = key.replace(" ", "");
                                      const categoryEventKey = `${submoduleId}_${idCategory}`;

                                      return (
                                        <>
                                          <Accordion
                                            key={i}
                                            defaultActiveKey={categoryEventKey}
                                          >
                                            <Form.Check
                                              checked={this.isSelectAllCategory(
                                                categoryPermission
                                              )}
                                              type="checkbox"
                                              id={categoryEventKey}
                                              label={
                                                <>
                                                  {key}
                                                  <AccordionToggle
                                                    style={{
                                                      border: "none",
                                                      background: "none",
                                                    }}
                                                    eventKey={categoryEventKey}
                                                    onClick={(evt) => {
                                                      this.setState({
                                                        activeKeys: {
                                                          ...this.state
                                                            .activeKeys,
                                                          [categoryEventKey]:
                                                            !this.state
                                                              .activeKeys[
                                                              categoryEventKey
                                                            ],
                                                        },
                                                      });
                                                    }}
                                                  >
                                                    <label className="expandCollapseLink">
                                                      {this.state.activeKeys[
                                                        categoryEventKey
                                                      ] === true ? (
                                                        <Image
                                                          className="collapseIcon"
                                                          src={CollapseIcon}
                                                        />
                                                      ) : (
                                                        <Image
                                                          className="expandIcon"
                                                          src={ExpandIcon}
                                                        />
                                                      )}
                                                    </label>
                                                  </AccordionToggle>
                                                </>
                                              }
                                              onChange={(e) =>
                                                this.selectAllCategory(
                                                  e,
                                                  categoryPermission
                                                )
                                              }
                                            />
                                            <AccordionCollapse
                                              eventKey={categoryEventKey}
                                            >
                                              <Row>
                                                {categoryPermission &&
                                                  categoryPermission.map(
                                                    (data) => {
                                                      const { name, id } = data;
                                                      return (
                                                        <>
                                                          <Col
                                                            lg={{
                                                              span: 3,
                                                              offset: 1,
                                                            }}
                                                            key={
                                                              categoryPermission.id
                                                            }
                                                            className="checkbox-holder px-0 py-1"
                                                          >
                                                            <Form.Check
                                                              checked={this.state.grantedGeneralPermissions.includes(
                                                                id
                                                              )}
                                                              type="checkbox"
                                                              id={id}
                                                              label={name}
                                                              className="m-2"
                                                              onChange={(e) =>
                                                                this.onCheckboxChange(
                                                                  e,
                                                                  id
                                                                )
                                                              }
                                                            />
                                                          </Col>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                              </Row>
                                            </AccordionCollapse>
                                          </Accordion>
                                        </>
                                      );
                                    })}
                                  </Col>
                                </Row>
                              </AccordionCollapse>
                            </Accordion>
                          );
                        })}
                      </div>
                    </>
                  )}
                {/* FOR PRODUCT MANAGEMENT PERMISSIONS */}
                {transactionManagementPermissions.length > 0 && (
                  <div>
                    <div className="px-4 pt-4">
                      <label className="px-4">
                        <strong>Transaction Management</strong>
                      </label>
                    </div>
                    <div className="px-4">
                      {transactionManagementPermissions.map((module, i) => {
                        const permissions = module.permissions;
                        const eventKey = module.name.replace(" ", "");
                        return (
                          <Accordion key={i} defaultActiveKey={eventKey}>
                            <Row lg="12">
                              <Col
                                lg="12"
                                className="px-5 checkbox-holder pt-4"
                                key={module.name}
                              >
                                <Form.Check
                                  type="checkbox"
                                  id={module.name}
                                  label={
                                    <>
                                      {module.name}
                                      <AccordionToggle
                                        style={{
                                          border: "none",
                                          background: "none",
                                        }}
                                        eventKey={eventKey}
                                        onClick={(evt) => {
                                          this.setState({
                                            activeKeys: {
                                              ...this.state.activeKeys,
                                              [eventKey]:
                                                !this.state.activeKeys[
                                                  eventKey
                                                ],
                                            },
                                          });
                                        }}
                                      >
                                        <label className="expandCollapseLink">
                                          {this.state.activeKeys[eventKey] ==
                                          true ? (
                                            <Image
                                              className="expandIcon"
                                              src={ExpandIcon}
                                            />
                                          ) : (
                                            <Image
                                              className="collapseIcon"
                                              src={CollapseIcon}
                                            />
                                          )}
                                        </label>
                                      </AccordionToggle>
                                    </>
                                  }
                                  checked={this.isSelectAllModule(module.name)}
                                  onChange={(e) =>
                                    this.onSelectAllModuleCheckboxChange(
                                      e,
                                      module.name
                                    )
                                  }
                                />
                              </Col>
                              <AccordionCollapse eventKey={eventKey}>
                                <Row lg="12">
                                  <Col lg={{ span: 11, offset: 1 }}>
                                    <Row>
                                      {permissions.map((permission) => {
                                        return (
                                          <>
                                            <Col
                                              lg={4}
                                              key={permission.id}
                                              className="checkbox-holder"
                                            >
                                              <Form.Check
                                                checked={this.state.grantedGeneralPermissions.includes(
                                                  permission.id
                                                )}
                                                type="checkbox"
                                                id={permission.id}
                                                label={permission.name}
                                                className="m-2"
                                                onChange={(e) =>
                                                  this.onCheckboxChange(
                                                    e,
                                                    permission.id
                                                  )
                                                }
                                              />
                                            </Col>
                                          </>
                                        );
                                      })}
                                    </Row>
                                  </Col>
                                </Row>
                              </AccordionCollapse>
                            </Row>
                          </Accordion>
                        );
                      })}
                    </div>
                    <hr className="mx-4 mt-5" />
                  </div>
                )}
                {centralWalletSystemPermissions.length > 0 && (
                  <div>
                    <div className="px-4 pt-4">
                      <label className="px-4">
                        <strong>Central Wallet System</strong>
                      </label>
                    </div>
                    <div className="px-4">
                      {centralWalletSystemGroupedPermissions.map(
                        (subModule, i) => {
                          const module = centralWalletSystemPermissions[0];
                          const permissions = subModule.permissions;
                          const eventKey = subModule.name.replace(" ", "");
                          return (
                            <Accordion key={i} defaultActiveKey={eventKey}>
                              <Row lg="12">
                                <Col
                                  lg="12"
                                  className="px-5 checkbox-holder pt-4"
                                  key={module.name}
                                >
                                  <Form.Check
                                    type="checkbox"
                                    id={subModule.name}
                                    label={
                                      <>
                                        {subModule.name}
                                        <AccordionToggle
                                          style={{
                                            border: "none",
                                            background: "none",
                                          }}
                                          eventKey={eventKey}
                                          onClick={(evt) => {
                                            this.setState({
                                              activeKeys: {
                                                ...this.state.activeKeys,
                                                [eventKey]:
                                                  !this.state.activeKeys[
                                                    eventKey
                                                  ],
                                              },
                                            });
                                          }}
                                        >
                                          <label className="expandCollapseLink">
                                            {this.state.activeKeys[eventKey] ==
                                            true ? (
                                              <Image
                                                className="expandIcon"
                                                src={ExpandIcon}
                                              />
                                            ) : (
                                              <Image
                                                className="collapseIcon"
                                                src={CollapseIcon}
                                              />
                                            )}
                                          </label>
                                        </AccordionToggle>
                                      </>
                                    }
                                    checked={this.isSelectAllModule(
                                      module.name,
                                      subModule.name
                                    )}
                                    onChange={(e) =>
                                      this.onSelectAllModuleCheckboxChange(
                                        e,
                                        module.name,
                                        subModule.name
                                      )
                                    }
                                  />
                                </Col>
                                <AccordionCollapse eventKey={eventKey}>
                                  <Row lg="12">
                                    <Col lg={{ span: 11, offset: 1 }}>
                                      <Row>
                                        {permissions.map((permission) => {
                                          return (
                                            <>
                                              <Col
                                                lg={`${
                                                  [
                                                    "Wallet Adjustment",
                                                    "Wallet Replenishment",
                                                  ].includes(subModule.name)
                                                    ? 8
                                                    : 4
                                                }`}
                                                key={permission.id}
                                                className="checkbox-holder"
                                              >
                                                <Form.Check
                                                  checked={this.state.grantedGeneralPermissions.includes(
                                                    permission.id
                                                  )}
                                                  type="checkbox"
                                                  id={`${subModule.name}.${permission.id}`}
                                                  label={permission.name}
                                                  className="m-2"
                                                  onChange={(e) =>
                                                    this.onCheckboxChange(
                                                      e,
                                                      permission.id,
                                                      permission.name
                                                    )
                                                  }
                                                />
                                              </Col>
                                              {(permission.name ==
                                                "Approve Wallet Adjustment" ||
                                                permission.name ==
                                                  "Approve Wallet Replenishment") && (
                                                <>
                                                  <div
                                                    style={{
                                                      display: `${
                                                        (
                                                          permission.name ==
                                                          "Approve Wallet Adjustment"
                                                            ? walletPermission
                                                                .walletAdjustment
                                                                .isDisplay
                                                            : walletPermission
                                                                .walletReplenishment
                                                                .isDisplay
                                                        )
                                                          ? "block"
                                                          : "none"
                                                      }`,
                                                    }}
                                                  >
                                                    <Col className="p-1">
                                                      <div className="input-block">
                                                        <Form.Control
                                                          name={
                                                            permission.name +
                                                            "-" +
                                                            "minAmount"
                                                          }
                                                          type="text"
                                                          onChange={
                                                            this.onInputChange
                                                          }
                                                          onBlur={this.onBlur}
                                                          value={
                                                            permission.name ==
                                                            "Approve Wallet Adjustment"
                                                              ? walletPermission
                                                                  .walletAdjustment
                                                                  .minAmount
                                                              : walletPermission
                                                                  .walletReplenishment
                                                                  .minAmount
                                                          }
                                                          className={`${
                                                            (
                                                              permission.name ==
                                                              "Approve Wallet Adjustment"
                                                                ? walletPermission
                                                                    .walletAdjustment
                                                                    .isMinAmountValid
                                                                : walletPermission
                                                                    .walletReplenishment
                                                                    .isMinAmountValid
                                                            )
                                                              ? ""
                                                              : "isError"
                                                          }`}
                                                        />
                                                        <span className="placeholder">
                                                          Minimum Amount for
                                                          Approval
                                                        </span>
                                                        {(permission.name ==
                                                        "Approve Wallet Adjustment"
                                                          ? !walletPermission
                                                              .walletAdjustment
                                                              .isMinAmountValid
                                                          : !walletPermission
                                                              .walletReplenishment
                                                              .isMinAmountValid) && (
                                                          <small
                                                            style={{
                                                              color: "#FF0000",
                                                            }}
                                                          >
                                                            Invalid Amount for
                                                            Approval
                                                          </small>
                                                        )}
                                                      </div>
                                                    </Col>
                                                    <Col className="p-1">
                                                      <div className="input-block">
                                                        <Form.Control
                                                          name={
                                                            permission.name +
                                                            "-" +
                                                            "maxAmount"
                                                          }
                                                          type="text"
                                                          onChange={
                                                            this.onInputChange
                                                          }
                                                          onBlur={this.onBlur}
                                                          value={
                                                            permission.name ==
                                                            "Approve Wallet Adjustment"
                                                              ? walletPermission
                                                                  .walletAdjustment
                                                                  .maxAmount
                                                              : walletPermission
                                                                  .walletReplenishment
                                                                  .maxAmount
                                                          }
                                                          className={`${
                                                            (
                                                              permission.name ==
                                                              "Approve Wallet Adjustment"
                                                                ? walletPermission
                                                                    .walletAdjustment
                                                                    .isMaxAmountValid
                                                                : walletPermission
                                                                    .walletReplenishment
                                                                    .isMaxAmountValid
                                                            )
                                                              ? ""
                                                              : "isError"
                                                          }`}
                                                        />
                                                        <span className="placeholder">
                                                          Maximum Amount for
                                                          Approval
                                                        </span>
                                                        {(permission.name ==
                                                        "Approve Wallet Adjustment"
                                                          ? !walletPermission
                                                              .walletAdjustment
                                                              .isMaxAmountValid
                                                          : !walletPermission
                                                              .walletReplenishment
                                                              .isMaxAmountValid) && (
                                                          <small
                                                            style={{
                                                              color: "#FF0000",
                                                            }}
                                                          >
                                                            Invalid Amount for
                                                            Approval
                                                          </small>
                                                        )}
                                                      </div>
                                                    </Col>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          );
                                        })}
                                      </Row>
                                    </Col>
                                  </Row>
                                </AccordionCollapse>
                              </Row>
                            </Accordion>
                          );
                        }
                      )}
                    </div>
                    <hr className="mx-4 mt-5" />
                  </div>
                )}
                {productManagementPermissions.length > 0 &&
                  this.state.productTypeList.length > 0 && (
                    <div>
                      <div className="px-4 pt-4">
                        <label className="px-4">
                          <strong>Product Management</strong>
                        </label>
                      </div>
                      <div className="px-4">
                        {this.state.productTypeList.map((productType, i) => {
                          return productManagementPermissions.map(
                            (module, i) => {
                              const permissions = module.permissions;
                              const eventKey = productType.name.replace(
                                " ",
                                ""
                              );
                              return (
                                <Accordion key={i} defaultActiveKey={eventKey}>
                                  <Row lg="12">
                                    <Col
                                      lg="12"
                                      className="px-5 checkbox-holder pt-4"
                                      key={module.name}
                                    >
                                      <Form.Check
                                        type="checkbox"
                                        id={productType.name}
                                        label={
                                          <>
                                            {productType.name}
                                            <AccordionToggle
                                              style={{
                                                border: "none",
                                                background: "none",
                                              }}
                                              eventKey={eventKey}
                                              onClick={(evt) => {
                                                this.setState({
                                                  activeKeys: {
                                                    ...this.state.activeKeys,
                                                    [eventKey]:
                                                      !this.state.activeKeys[
                                                        eventKey
                                                      ],
                                                  },
                                                });
                                              }}
                                            >
                                              <label className="expandCollapseLink">
                                                {this.state.activeKeys[
                                                  eventKey
                                                ] == true ? (
                                                  <Image
                                                    className="expandIcon"
                                                    src={ExpandIcon}
                                                  />
                                                ) : (
                                                  <Image
                                                    className="collapseIcon"
                                                    src={CollapseIcon}
                                                  />
                                                )}
                                              </label>
                                            </AccordionToggle>
                                          </>
                                        }
                                        checked={this.isSelectAllModule(
                                          module.name,
                                          productType.code
                                        )}
                                        onChange={(e) =>
                                          this.onSelectAllModuleCheckboxChange(
                                            e,
                                            module.name,
                                            productType.code
                                          )
                                        }
                                      />
                                    </Col>
                                    <AccordionCollapse eventKey={eventKey}>
                                      <Row lg="12">
                                        <Col lg={{ span: 11, offset: 1 }}>
                                          <Row>
                                            {permissions.map((permission) => {
                                              return (
                                                <>
                                                  <Col
                                                    lg={4}
                                                    key={permission.id}
                                                    className="checkbox-holder"
                                                  >
                                                    <Form.Check
                                                      checked={
                                                        this.state
                                                          .grantedProductPermissions &&
                                                        productType &&
                                                        permission
                                                          ? this.state.grantedProductPermissions[
                                                              productType.code
                                                            ]?.includes(
                                                              permission.id
                                                            )
                                                          : false
                                                      }
                                                      type="checkbox"
                                                      id={`${productType.code}.${permission.id}`}
                                                      label={permission.name}
                                                      className="m-2"
                                                      onChange={(e) =>
                                                        this.onCheckboxChange(
                                                          e,
                                                          permission.id,
                                                          productType.code
                                                        )
                                                      }
                                                    />
                                                  </Col>
                                                </>
                                              );
                                            })}
                                          </Row>
                                        </Col>
                                      </Row>
                                    </AccordionCollapse>
                                  </Row>
                                </Accordion>
                              );
                            }
                          );
                        })}
                      </div>
                      <hr className="mx-4 mt-5" />
                    </div>
                  )}
                {/* FOR NON PRODUCT MANAGEMENT PERMISSIONS */}
                {generalPermissions.map((module, i) => {
                  const subModules = module.name === 'Channel Management' ?  this.mapChannelModules(module) : this.groupPermissionBySubModule(module);
                  return (
                    subModules.length > 0 && (
                      <div>
                        <div className="px-4 pt-4">
                          <label className="px-4">
                            <strong>{module.name}</strong>
                          </label>
                        </div>
                        <div className="px-4">
                          {subModules.map((subModule, i) => {
                            const permissions = subModule.permissions;
                            const eventKey = subModule.name.replace(" ", "");
                            return (
                              <Accordion key={i} defaultActiveKey={eventKey}>
                                <Row lg="12">
                                  <Col
                                    lg="12"
                                    className="px-5 checkbox-holder pt-4"
                                    key={module.name}
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      id={subModule.name}
                                      label={
                                        <>
                                          {subModule.name}
                                          <AccordionToggle
                                            style={{
                                              border: "none",
                                              background: "none",
                                            }}
                                            eventKey={eventKey}
                                            onClick={(evt) => {
                                              this.setState({
                                                activeKeys: {
                                                  ...this.state.activeKeys,
                                                  [eventKey]:
                                                    !this.state.activeKeys[
                                                      eventKey
                                                    ],
                                                },
                                              });
                                            }}
                                          >
                                            <label className="expandCollapseLink">
                                              {this.state.activeKeys[
                                                eventKey
                                              ] == true ? (
                                                <Image
                                                  className="expandIcon"
                                                  src={ExpandIcon}
                                                />
                                              ) : (
                                                <Image
                                                  className="collapseIcon"
                                                  src={CollapseIcon}
                                                />
                                              )}
                                            </label>
                                          </AccordionToggle>
                                        </>
                                      }
                                      checked={this.isSelectAllModule(
                                        module.name,
                                        subModule.name
                                      )}
                                      onChange={(e) =>
                                        this.onSelectAllModuleCheckboxChange(
                                          e,
                                          module.name,
                                          subModule.name
                                        )
                                      }
                                    />
                                  </Col>
                                  <AccordionCollapse eventKey={eventKey}>
                                    <Row lg="12">
                                      <Col lg={{ span: 11, offset: 1 }}>
                                        <Row>
                                          {permissions.map((permission) => {
                                            return (
                                              <>
                                                <Col
                                                  lg={4}
                                                  key={permission.id}
                                                  className="checkbox-holder"
                                                >
                                                  <Form.Check
                                                    checked={this.state.grantedGeneralPermissions.includes(
                                                      permission.id
                                                    )}
                                                    type="checkbox"
                                                    id={`${subModule.name}.${permission.id}`}
                                                    label={permission.name}
                                                    className="m-2"
                                                    onChange={(e) =>
                                                      this.onCheckboxChange(
                                                        e,
                                                        permission.id,
                                                        permission.name
                                                      )
                                                    }
                                                  />
                                                </Col>
                                              </>
                                            );
                                          })}
                                        </Row>
                                      </Col>
                                    </Row>
                                  </AccordionCollapse>
                                </Row>
                              </Accordion>
                            );
                          })}
                        </div>
                        <hr className="mx-4 mt-5" />
                      </div>
                    )
                  );
                })}
              </Form.Group>
              {this.state.isPermissionListEmpty && (
                <small className="ml-3" style={{ color: "#FF0000" }}>
                  <RiErrorWarningFill /> Permission/s is/are required.
                </small>
              )}
              <Row className="m-2">
                <Col className="text-center">
                  <div>{this.isDisabled()}</div>
                  <Button
                    bsPrefix="platform-btn platform-btn-cancel"
                    className="m-2"
                    onClick={this.toggleModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    bsPrefix="platform-btn platform-btn-add"
                    className="m-2"
                    disabled={this.isDisabled()}
                  >
                    Create
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default AddRole;
