import { Component } from "react";
import { Button, Form, Modal, ModalBody, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import "./BlockModal.css";
import { isUserHasAccess } from "../../shared/utility/utils";
import HTTP from "../../shared/utility/http";
import { hasSpecialCharacter, validateMaxLength } from "./Utility/Validator";

const BLOCK_USER_PERMISSION = {
  scope: "uam.users.block",
  resource: "/v2/users/{userId}/block",
};

const UNBLOCK_USER_PERMISSION = {
  scope: "uam.users.unblock",
  resource: "/v2/users/{userId}/unblock",
};

const actions = [
  { prop: "block", value: "Block" },
  { prop: "unblock", value: "Unblock" },
];

const initialState = {
  showModal: false,
  type: "block",
  reason: {
    value: "",
    valid: true,
    errorMessage: "",
  },
};
class BlockModal extends Component {
  userDetail = null;
  placeholder = "";

  constructor(props) {
    super(props);
    this.state = initialState;

    this.toggleModal = this.toggleModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggleModal = async (type) => {
    //reset state
    this.setState(initialState);

    const userDetail = this.props.data.find(
      (item) => item.id === this.props.userId.id
    );

    this.placeholder = `Explain the reason for ${type}ing the user`;

    this.userDetail = userDetail;

    if (type.toUpperCase() == "BLOCK") {
      if (await isUserHasAccess(BLOCK_USER_PERMISSION)) {
        await this.setState({ showModal: !this.state.showModal, type: type });
      } else {
        window.isAccessDenied = true;
      }
    } else {
      if (await isUserHasAccess(UNBLOCK_USER_PERMISSION)) {
        await this.setState({ showModal: !this.state.showModal, type: type });
      } else {
        window.isAccessDenied = true;
      }
    }
  };

  handleChange(e) {
    const _v = e.target.value;
    const maxLength = validateMaxLength(_v, 1000);
    const specialChar = hasSpecialCharacter(_v);

    let _validate;
    if (!maxLength.value) {
      _validate = maxLength;
    } else if (specialChar.value) {
      specialChar.value = false;
      _validate = specialChar;
    } else {
      _validate = {
        value: true,
      };
    }

    this.setState({
      reason: {
        value: e.target.value,
        valid: _validate.value,
        errorMessage: _validate.message,
      },
    });
  }

  handleSubmit() {
    let url = `/v2/users/${this.props.userId.id}/`;

    if (this.state.type == "unblock") {
      url += "unblock";
    } else if (this.state.type == "block") {
      url += "block";
    }

    HTTP.put(url, {
      reason: this.state.reason.value,
    })
      .then(() => {
        this.props.parentCallback(
          `User "${
            this.userDetail?.username
          }" has been ${this.state.type.toUpperCase()}ED`,
          "success"
        );
      })
      .catch((e) => {
        this.props.parentCallback(
          `Failed to ${this.state.type.toLowerCase()} user "${
            this.userDetail?.username
          }"`,
          "failed"
        );
        console.log(e);
      })
      .finally(() => {
        this.setState({ showModal: false });
      });
  }

  render() {
    return (
      <Modal
        show={this.state.showModal}
        size="lg"
        keyboard={false}
        centered
        onHide={() => {
          this.toggleModal(this.state.type);
        }}
        className="blockModal"
      >
        <ModalHeader className="blockHeader" style={{ border: "0 none" }}>
          <ModalTitle>
            {
              actions.find((v) => {
                return v.prop == this.state.type;
              })?.value
            }{" "}
            User{" "}
            {this.userDetail?.firstname +
              " " +
              this.userDetail?.lastname +
              `   (${this.userDetail?.username})`}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          {this.state.type == "unblock" && (
            <p className="spiel">
              This action will{" "}
              {`${this.state.type} ${this.userDetail?.firstname} ${this.userDetail?.lastname}'s`}{" "}
              login. This will also send a link to the user's email (
              {this.userDetail?.email}) to reset his/her password.
            </p>
          )}
          <Form>
            <Form.Group className="mb-3" controlId="reason">
              <Form.Label>
                <h6>Reason:</h6>
              </Form.Label>
              <Form.Control
                onChange={this.handleChange}
                as="textarea"
                rows={3}
                placeholder={this.placeholder}
                isInvalid={!this.state.reason.valid}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.reason.errorMessage}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </ModalBody>
        <Modal.Footer className="reset-footer">
          <Button
            className="user-btn"
            style={{ width: "140px" }}
            onClick={() => this.setState({ showModal: false })}
          >
            Cancel
          </Button>
          <Button
            className="user-btn close-btn"
            style={{ width: "140px" }}
            onClick={this.handleSubmit}
            disabled={
              this.state.reason.value.trim() == "" || !this.state.reason.valid
            }
          >
            {
              actions.find((v) => {
                return v.prop == this.state.type;
              })?.value
            }
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default BlockModal;
